<template>
    <v-container class="py-8 px-6 pl-12" fluid>
        <v-card outlined style="border: 0px solid white" class="mt-n10">
            <v-card-text class="mt-n3">
                <div class="cont-title" style="float: left;">{{ pageTitle }}</div>
            </v-card-text>
        </v-card>
        <v-row class="mt-16">
            <v-col class="mb-0">
                <router-view></router-view>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'stats',
    components: {},
    data: () => ({}),
    created() {
        this.$store.commit('layoutFlag', true)
        // this.$store.commit('menuId', 'stats')

    },
    computed: {
        pageTitle() {
            let ret = '통계'
            if (this.$route && this.$route.meta && this.$route.meta.title) {
                ret = this.$route.meta.title
            }
            return ret
        }
    },
    mounted() {
    },
    methods: {}
}
</script>

